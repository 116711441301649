function preloadAsset (src) {
  return new Promise((resolve, reject) => {

    var request = new XMLHttpRequest()
    var video = document.createElement("video")

    request.onload = function() {
        video.src = URL.createObjectURL(request.response)
        resolve(video)
    };

    request.onerror = request.onabort = function() {
      reject(video)
    }

    request.open("GET", src)

    request.responseType = "blob"
    request.send()
  })
}

export default async function PreLoaderComponent(assets) {
  return true
  
  let assetsPreloaded = false
  let isCancelled = false

  async function effect() {
    if (isCancelled) {
      return
    }

    const assetsPromiseList = []
    for (const i of assets) {
      assetsPromiseList.push(preloadAsset(i))
    }

    await Promise.all(assetsPromiseList)

    if (isCancelled) {
      return
    }

    assetsPreloaded = true
  }

  await effect()

  return assetsPreloaded
}
