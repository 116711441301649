import { useState } from "react"
import { motion } from "framer-motion"

import EmbedFormContactComponent from "components/EmbedFormContact/EmbedFormContactComponent"

import "./SolutionsComponent.scss"

export default function SolutionsComponent( props ) {
    const [showModal, setShowModal] = useState(false)

    const hideModal = () => {
        setShowModal(false)
    }

    return (
        <>
            {props.solutions.length ? (
                <div className="solutions_component">
                    <ul className="solutions_component__list">
                        {props.solutions.map((solution, index) => (
                            <motion.li 
                                key={index} 
                                className="solutions_component__list__item"
                                initial={{ y: "10%", opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{ duration: 0.6, delay: 0.2*index, ease: [0.650, 0.160, 0.000, 1.095] }}
                                viewport={{ amount: 0.2, once: true }}
                            >
                                <img className="solutions_component__list__item__image" src={solution.image.url} alt={solution.image.alt} />
                                <div className="solutions_component__list__item__content">
                                    <div className="solutions_component__list__item__content__header">
                                        <h3 className="solutions_component__list__item__content__header__title">{solution.name}</h3>
                                        <p className="solutions_component__list__item__content__header__description">{solution.description}</p>
                                    </div>
                                    <div className="solutions_component__list__item__content__footer">
                                        <button onClick={() => setShowModal(true)} className="solutions_component__list__item__content__footer__button">
                                            <span className="solutions_component__list__item__content__footer__button__label">{solution.cta_text}</span>
                                            <span className="solutions_component__list__item__content__footer__button__hover_label">{solution.cta_text}</span>
                                        </button>
                                        <p className="solutions_component__list__item__content__footer__description">{solution.description}</p>
                                    </div>
                                </div>
                            </motion.li>
                        ))}
                    </ul>
                </div>
            ) : null}
            {props.hbspt ? <EmbedFormContactComponent hbspt={props.hbspt} form_id={props.form_id} showModal={showModal} hideModal={hideModal} /> : null}
        </>
    )
}