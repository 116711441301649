import { Link } from "react-router-dom"

import "./FooterComponent.scss"

// import FormComponent from "components/Form/FormComponent"

import logoGreen from "assets/images/enersol-logo-green.svg"
import facebookIcon from "assets/images/facebook-icon.svg"
// import instagramIcon from "assets/images/instagram-icon.svg"
// import twitterIcon from "assets/images/twitter-icon.svg"
// import youtubeIcon from "assets/images/youtube-icon.svg"
import linkedinIcon from "assets/images/linkedin-icon.svg"

// const newsletterSignupFormFields = [
//     {
//         type: "text",
//         name: "name",
//         id: "name",
//         placeholder: "Your Name"
//     },
//     {
//         type: "email",
//         name: "email",
//         id: "email",
//         placeholder: "Your Email Address"
//     }
// ]

export default function FooterComponent( props ) {
    const date = new Date()

    return (
        <footer className="footer_component">
            <div className="footer_componet__content">
                <div className="footer_componet__content__column v_logo">
                    <img className="footer_component__logo" src={logoGreen} alt="Enersol" />
                    {/* <h4 className="footer_componet__content__column__title">Products</h4> */}
                    
                </div>
                <div className="footer_componet__content__column">
                    {/* <h4 className="footer_componet__content__column__title">Company</h4> */}
                    <ul className="footer_componet__content__column__list">
                        <li className="footer_componet__content__column__list__item">
                            <Link to="/distributors" className="footer_componet__content__column__list__item__link">Distributors</Link>
                        </li>
                        <li className="footer_componet__content__column__list__item">
                            <Link to="/categories/modules" className="footer_componet__content__column__list__item__link">Modules</Link>
                        </li>
                        <li className="footer_componet__content__column__list__item">
                            <Link to="/categories/storage" className="footer_componet__content__column__list__item__link">Storage</Link>
                        </li>
                        <li className="footer_componet__content__column__list__item">
                            <Link to="/categories/inverters" className="footer_componet__content__column__list__item__link">Inverters</Link>
                        </li>
                    </ul>

                </div>
                <div className="footer_componet__content__column">
                    {/* <h4 className="footer_componet__content__column__title">Legal</h4>
                    <ul className="footer_componet__content__column__list">
                        
                    </ul> */}
                    <ul className="footer_componet__content__column__list">
                        <li className="footer_componet__content__column__list__item">
                            <Link to="/about-us" className="footer_componet__content__column__list__item__link">About Us</Link>
                        </li>
                        {/* <li className="footer_componet__content__column__list__item">
                            <Link to="/news-and-resources" className="footer_componet__content__column__list__item__link">News &amp; Resources</Link>
                        </li> */}
                        <li className="footer_componet__content__column__list__item">
                            <Link to="/downloads" className="footer_componet__content__column__list__item__link">Downloads</Link>
                        </li>
                        {/* <li className="footer_componet__content__column__list__item">
                            <Link to="/support" className="footer_componet__content__column__list__item__link">Support</Link>
                        </li> */}
                        <li className="footer_componet__content__column__list__item">
                            <Link to="/terms-and-conditions" className="footer_componet__content__column__list__item__link">Terms &amp; Conditions</Link>
                        </li>
                        {/* <li className="footer_componet__content__column__list__item">
                            <Link to="/privacy-policy" className="footer_componet__content__column__list__item__link">Privacy Policy</Link>
                        </li> */}
                    </ul>
                </div>
                <div className="footer_componet__content__column v_contact">
                    {/* <h4 className="footer_componet__content__column__title">Connect</h4> */}
                    {/* <h4 className="footer_componet__content__column__title">Signup for our mailing list</h4> */}
                    {/* <FormComponent fields={newsletterSignupFormFields} button_label="Sign Up" button_hover="Submit" button_alt={true} /> */}

                    <ul className="footer_componet__content__column__social_list">
                        <li className="footer_componet__content__column__social_list__item">
                            <a className="footer_componet__content__column__social_list__link" href="https://www.facebook.com/enerSol.Africa" target="_blank" rel="noreferrer">
                                <img className="footer_componet__content__column__social_list__link__image" src={facebookIcon} alt="Facebook" />
                            </a>                        
                        </li>
                        {/* <li className="footer_componet__content__column__social_list__item">
                            <a className="footer_componet__content__column__social_list__link" href="https://www.instagram.com/enerSol.Africa" target="_blank" rel="noreferrer">
                                <img className="footer_componet__content__column__social_list__link__image" src={instagramIcon} alt="Instagram" />
                            </a>                        
                        </li>
                        <li className="footer_componet__content__column__social_list__item">
                            <a className="footer_componet__content__column__social_list__link" href="https://www.twitter.com/enerSol.Africa" target="_blank" rel="noreferrer">
                                <img className="footer_componet__content__column__social_list__link__image" src={twitterIcon} alt="Twitter" />
                            </a>                        
                        </li>
                        <li className="footer_componet__content__column__social_list__item">
                            <a className="footer_componet__content__column__social_list__link" href="https://www.youtube.com/enerSol.Africa" target="_blank" rel="noreferrer">
                                <img className="footer_componet__content__column__social_list__link__image" src={youtubeIcon} alt="YouTube" />
                            </a>                        
                        </li> */}
                        <li className="footer_componet__content__column__social_list__item">
                            <a className="footer_componet__content__column__social_list__link" href="https://www.linkedin.com/company/enersol-international" target="_blank" rel="noreferrer">
                                <img className="footer_componet__content__column__social_list__link__image" src={linkedinIcon} alt="LinkedIn" />
                            </a>                        
                        </li>
                    </ul>

                    <ul className="footer_componet__content__column__list">
                        <li className="footer_componet__content__column__list__item">
                            <a className="footer_componet__content__column__list__item__link" href="https://share.hsforms.com/1vuJDKtj_R722mAF5iqvjkAbsuuy" target="_blank">Signup for our mailing list &rarr;</a>
                        </li>
                        <li className="footer_componet__content__column__list__item">
                            <a className="footer_componet__content__column__list__item__link" href="mailto:sales@enersol.africa">sales@enersol.africa</a>
                        </li>
                        <li className="footer_componet__content__column__list__item">
                            <a className="footer_componet__content__column__list__item__link" href="mailto:support@enersol.africa">support@enersol.africa</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="footer_component__copyright">
                <p className="footer_component__copyright__text">
                    &copy; {date.getFullYear()} enerSol Africa. All rights reserved.
                </p>
            </div>
        </footer>
    )
}