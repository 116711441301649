import { useEffect } from "react"
import { motion, useAnimation } from "framer-motion"

import ButtonComponent from "components/Button/ButtonComponent"

const transition = {
    duration: 1,
    ease: [0.5, 0, 0.1, 1]
}

const subtitleTransition = {
    duration: 0.8, 
    ease: "easeInOut"
}

export default function CategoryViewDescription( props ) {
    const controls = useAnimation()

    useEffect(() => {
        controls.start((params) => ({
            ...params.values,
            duration: params.duration,
            transition: { delay: params.delay + params.index * params.duration }
        }))
    }, [])

    return (
        <div className="category_view__description">
            <motion.h3 
                className="category_view__description__subtitle"
                initial={{ y: "100%", opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ amount: 0.4, once: true }}
                transition={{ ...subtitleTransition, delay: 4.8 }}
            >
                {props.subtitle}
            </motion.h3>
            <motion.h2 
                className="category_view__description__title"
                initial={{ x: "10%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ ...transition, delay: 3.3, duration: 1.5 }}
            >
                {props.title}
            </motion.h2>
            <motion.div 
                className="category_view__description__content"
                initial={{ y: "10%", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ ...transition, delay: 5.6 }}
            >
                {props.description}
            </motion.div>
            <div className="category_view__description__navigation">
                {props.categories.map((child_category, index) => (
                    <motion.span
                        style={{ display: "inline-block" }}
                        initial={{ x: "10%", opacity: 0 }}
                        animate={controls}
                        transition={{ duration: 0.8, delay: 2.8, ease: "easeInOut" }}
                        whileInView="visible"
                        viewport={{ amount: 1, once: true }}
                        custom={{
                            index: index,
                            delay: 2.8,
                            duration: 0.8,
                            values: {
                                x: 0,
                                opacity: 1
                            }
                        }}
                        key={index}
                    >
                        <ButtonComponent
                            href={`/categories/${child_category.slug}`}
                            label={child_category.name}
                            hover="View"
                            alt={true} 
                        />
                    </motion.span>
                ))}
            </div>
        </div>
    )
}