import { useState } from "react"
import { motion } from "framer-motion"

import ButtonComponent from "components/Button/ButtonComponent"

import "components/Categories/CategoriesComponent.scss"

import categoriesModulesImage from "assets/images/categories/modules.jpg"
import categoriesStorageImage from "assets/images/categories/storage.jpg"
import categoriesInvertersImage from "assets/images/categories/inverters.jpg"

const categories = [
    {
        id: 1,
        is_active: true,
        title: "Modules",
        subtitle: "Photovoltaic",
        description: "enerSol's state-of-the-art photovoltaic modules revolutionising the way we harness solar energy. Our specifically crafted modules deliver high performance, durability, and efficiency, making them the ideal choice for residential, commercial, water-pumping and off-grid applications.",
        image: categoriesModulesImage,
        link: "/categories/modules"
    },
    {
        id: 2,
        is_active: false,
        title: "Storage",
        subtitle: "Solar",
        description: "enerSol's innovative range of storage solutions is designed to ensure that your energy lasts longer. We engineered our storage systems as an enhancement of renewable energy sources to ensure seamless integration from the panels into your appliances.",
        image: categoriesStorageImage,
        link: "/categories/storage"

    },
    {
        id: 3,
        is_active: false,
        title: "Inverters",
        subtitle: "High Voltage",
        description: "enerSol High Voltage inverters offer increased efficiency, parallel capability, high-speed MPPT algorithm, and decreased installation cost and time.",
        image: categoriesInvertersImage,
        link: "/categories/inverters"
    }
]

const subtitleTransition = {
    duration: 0.8, 
    delay: 0.6,
    ease: "easeInOut"
}

export default function CategoriesComponent( props ) {
    const [activeCategory, setActiveCategory] = useState(0)

    return (
        <div className="categories_component">
            <motion.h2 
                className="categories_component__title"
                initial={{ y: "100%", opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ amount: 1, once: true }}
                transition={subtitleTransition}
            >
                Our Product Range
            </motion.h2>
            {props.categories.length ? (
                <motion.ul 
                    className="categories_component__list"
                    initial={{ y: "10%", opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    viewport={{ amount: 0.1, once: true }}
                    transition={subtitleTransition}
                >
                    {props.categories.map((category, index) => (
                        <li 
                            key={index} 
                            className={ activeCategory == index ? "categories_component__list__item active" : "categories_component__list__item" }
                            onMouseEnter={() => setActiveCategory(index)}
                        >
                            <div className="categories_component__list__item__content">
                                <div>
                                    <h4 className="categories_component__list__item__content__subtitle">{category.metadata.subtitle}</h4>
                                    <h3 className="categories_component__list__item__content__title">{category.name}</h3>
                                    <div className="categories_component__list__item__content__footer">
                                        <div className="categories_component__list__item__content__footer__description">
                                            <p>{category.description}</p>
                                        </div>
                                        {/* <img className="categories_component__list__item__content__footer__image" src={category.image} alt="PV Solar Modules" /> */}
                                    </div>
                                </div>
                                <ButtonComponent to={`/categories/${category.slug}`} label="View Range" hover="View" alt={true} />
                            </div>
                        </li>
                    ))}
                </motion.ul>
            ) : null}
        </div>
    )
} 