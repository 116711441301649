import { useState } from "react"

import DownloadsListItem from "views/Downloads/DownloadsListItem"

export default function DownloadsList( props ) {
    const [activeFilter, setActiveFilter] = useState(typeof props.categories.terms[0].terms[0].id !== 'undefined' ? props.categories.terms[0].terms[0].id : null)
    
    return (
        <div className="downloads_view__downloads">
            <ul className="downloads_view__downloads__filter">
                {props.categories.terms.map( ( category, index ) => (
                    <li key={index} className="downloads_view__downloads__filter__item">
                        <span>{category.name}</span>
                        {category.terms.length ? (
                            <ul className="downloads_view__downloads__filter__item__sub_categories">
                                {category.terms.map( ( subCategory, subCategoryIndex ) => (
                                    <li key={subCategoryIndex} className="downloads_view__downloads__filter__item__sub_categories__item"  onClick={() => setActiveFilter(subCategory.id)}>
                                        <span>{subCategory.name}</span>
                                    </li>
                                ))}
                            </ul>
                        ) : null}
                    </li> 
                ))}
            </ul>
            <ul className="downloads_view__downloads__list">
                <li className="downloads_view__downloads__list__item v_title">
                    <span className="downloads_view__downloads__list__item__label__title">Name</span>
                    <span>Download</span>
                </li>
                {props.categories.terms.map( ( category, index ) => ( 
                    <div key={index}>
                    {category.terms.length ? (
                        <>
                        {category.terms.map( ( subCategory, subCategoryIndex ) => (
                            <div key={subCategoryIndex}>
                                {subCategory.id === activeFilter ? subCategory.posts.map( ( download, downloadIndex ) => <DownloadsListItem key={downloadIndex} name={download.title} warranty={download.metadata.warranty} certificate={download.metadata.certificate} link={download.metadata.datasheet.url} /> ) : null}
                            </div>
                        ))}
                        </>
                    ) : null}
                    </div>
                ))}
            </ul>
        </div>
    )
}