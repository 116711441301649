import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { motion } from "framer-motion"

import "./HeaderComponent.scss"

import logoWhite from "assets/images/enersol-logo-white.svg"
import logoGreen from "assets/images/enersol-logo-green.svg"

const menuItems = [
    {
        name: "Modules",
        link: "/categories/modules"
    },
    {
        name: "Storage",
        link: "/categories/storage"
    },
    {
        name: "Inverters",
        link: "/categories/inverters"
    },
    {
        name: "Charge Controllers",
        link: "/categories/charge-controllers"
    },
    {
        name: "About Us",
        link: "/about-us"
    },
    {
        name: "Distributors",
        link: "/distributors"
    }
]

const subMenuItems = [
    {
        name: "Distributors",
        link: "/distributors"
    }
]

export default function HeaderComponent( props ) {
    const [language, setLanguage] = useState( { code: 'EN', name: 'English' } );
    const [scrollTop, setScrollTop] = useState(0);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [classList, setClassList] = useState(['header_component']);
    const [menuClassList, setmenuClassList] = useState(['header_component__menu']);

    useEffect(() => {
        const handleScroll = (event) => {
          setScrollTop(window.scrollY)
        }
    
        window.addEventListener('scroll', handleScroll)
    
        return () => {
          window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        setClassList(props.isCollapsible !== true || scrollTop > 0 ? ['header_component', 'v_scroll'] : ['header_component'])
    }, [scrollTop, props.isCollapsible])

    useEffect(() => {
        setmenuClassList(isMenuVisible ? ['header_component__menu', 'is_visible'] : ['header_component__menu'])
    }, [isMenuVisible])

    return (
        <header className={classList.join(" ")}>
            <Link className="header_component__logo" to="/">
                <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.8, ease: "easeInOut" }}
                >
                    <img className="header_component__logo__image v_initial" src={logoWhite} alt="Enersol" />
                    <img className="header_component__logo__image v_scroll" src={logoGreen} alt="Enersol" />
                </motion.span>
            </Link>
            {menuItems.length ? (
                <nav className={menuClassList.join(" ")}>
                    <div className="header_component__menu__header">
                        <Link to="/" className="header_component__menu__header__logo">
                            <img className="header_component__menu__header__logo__image" src={logoGreen} alt="Enersol" />
                        </Link>
                        <button
                            className="header_component__menu__header__close_button"
                            onClick={e => setIsMenuVisible(false)}
                        />
                    </div>
                    <ul className="header_component__menu__list">
                        {menuItems.map((item, index) => (
                            <motion.li 
                                className="header_component__menu__list__item" 
                                key={index}
                                initial={{ y: "-100%", opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 0.4, delay: 0.8+0.2*index, ease: [0.465, 0.280, 0.185, 1.250] }}
                            >
                                <Link className="header_component__menu__list__item__link" to={item.link}>{item.name}</Link>
                            </motion.li>
                        ))}
                    </ul>
                </nav>
            ) : null}
            {subMenuItems.length ? (
                <nav className="header_component__sub_menu">
                    <ul className="header_component__sub_menu__list">
                        {/*
                        {subMenuItems.map((item, index) => (
                            <motion.li 
                                className="header_component__sub_menu__list__item v_sub_menu_item" key={index}
                                initial={{ y: "-100%", opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 0.4, delay: 2.6+0.2*index, ease: [0.465, 0.280, 0.185, 1.250] }}
                            >
                                <Link className="header_component__sub_menu__list__item__link" to={item.link}>{item.name}</Link>
                            </motion.li>
                        ))}
                        <motion.li 
                            className="header_component__sub_menu__list__item v_lanaguage"
                            initial={{ y: "-100%", opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.4, delay: 2.2, ease: [0.465, 0.280, 0.185, 1.250] }}
                        >
                            <button className="header_component__sub_menu__list__item__link">
                                {language.code}
                                <img className="header_component__sub_menu__list__item__link__icon v_initial" src={languageIcon} alt="Select Language" />
                                <img className="header_component__sub_menu__list__item__link__icon v_scroll" src={languageBlackIcon} alt="Select Language" />
                            </button>
                        </motion.li>
                        */}
                        <li className="header_component__sub_menu__list__item v_menu_button">
                            <button 
                                className="header_component__sub_menu__list__item__link" 
                                onClick={e => setIsMenuVisible(true)}
                            >
                                <span className="header_component__sub_menu__list__item__link__menu_icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>
                        </li>
                    </ul>
                </nav>
            ) : null}
        </header>
    )
}