import { useEffect, useState } from "react"
import { Helmet } from "react-helmet";

import "./AboutView.scss"

import PageHeroComponent from "components/PageHero/PageHeroComponent"

export default function AboutView() {
    const slug = 'about-us';
    const [data, setData] = useState(null)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setData(data))
    }, [])

    if (!data) return null

    return (
        <div className="about_view">
            <Helmet>
                {data.metadata.seo_title ? <title>{data.metadata.seo_title}</title> : null}
                {data.metadata.seo_description ? <meta name="description" content={data.metadata.seo_description} /> : null}
            </Helmet>
            <PageHeroComponent title={data.title} description={data.metadata.description} image={data.metadata.image.url} />
            <div className="about_view__content_blocks">
                {data.metadata.sections.length ? (
                    <ul className="about_view__content_blocks__list">
                        {data.metadata.sections.map((section, index) => (
                            <li key={index} className="about_view__content_blocks__list__item">
                                {section.image ? (
                                    <div className="about_view__content_blocks__list__item__image">
                                        <img className="about_view__content_blocks__list__item__image__src" src={section.image.url} alt="" />
                                    </div>
                                ) : null }
                                <div className="about_view__content_blocks__list__item__content">
                                    <h3 className="about_view__content_blocks__list__item__content__title">{section.title}</h3>
                                    <div className="about_view__content_blocks__list__item__content__text" dangerouslySetInnerHTML={{__html: section.description}}></div>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : null }
            </div>
        </div>
    )
}