import { motion, AnimatePresence } from "framer-motion"

import VideoComponent from "components/Video/VideoComponent"
import ButtonComponent from "components/Button/ButtonComponent"

const transition = {
    duration: 2,
    ease: [0.43, 0.13, 0.23, 0.96]
}

const upTransition = {
    duration: 0.8,
    delay: 2.3,
    ease: "easeOut"
}

const leftTransition = {
    duration: 1,
    delay: 1.9,
    ease: "easeOut"
}

const downTransition = {
    duration: 0.8,
    delay: 3.3,
    ease: "easeOut"
}

const inFromRightTransition = {
    duration: 0.8,
    delay: 0,
    ease: "easeOut"
}

export default function HeroComponentItem( props ) {

    return (
        <AnimatePresence mode="wait">
            {props.is_active ? (
                <motion.li 
                    key="hero_component__list__item"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { ...transition } }}
                    transition={transition}
                    className="hero_component__list__item"
                >
                    <VideoComponent src={props.video} />
                    <div className="hero_component__content">
                        <div className="hero_component__content__titles">
                            <motion.h2
                                initial={props.hasIntroAnimationPlayed ? { opacity: 0 } : { y: '100%', opacity: 0 }}
                                animate={props.hasIntroAnimationPlayed ? { opacity: 1 } : { y: 0, opacity: 1 }}
                                transition={upTransition}
                                className="hero_component__content__titles__subtitle"
                            >
                                {props.subtitle}
                            </motion.h2>
                            <motion.h1
                                initial={props.hasIntroAnimationPlayed ? {} : { x: '90%' }}
                                animate={props.hasIntroAnimationPlayed ? {} : { x: 0 }}
                                transition={leftTransition}
                                className="hero_component__content__titles__title"
                            >
                                <motion.span 
                                    initial={props.hasIntroAnimationPlayed ? {} : { x: '-90%' }}
                                    animate={props.hasIntroAnimationPlayed ? {} : { x: 0 }}
                                    transition={leftTransition}
                                    className="animation"
                                >
                                    <motion.span 
                                        initial={props.hasIntroAnimationPlayed ? {} : { x: '90%' }}
                                        animate={props.hasIntroAnimationPlayed ? {} : { x: 0 }}
                                        transition={{ ...leftTransition, ease: [0.5, 0, 0.1, 1] }}
                                        className="animation__outer animation__energising_africa"
                                    >
                                        <motion.span
                                            initial={props.hasIntroAnimationPlayed ? {} : { x: '-90%' }}
                                            animate={props.hasIntroAnimationPlayed ? {} : { x: 0 }}
                                            transition={leftTransition}
                                            className="animation__energising_africa__inner"
                                        >
                                            {props.title_line_1}
                                        </motion.span>
                                    </motion.span>
                                    <motion.span 
                                        initial={props.hasIntroAnimationPlayed ? {} : { x: '90%' }}
                                        animate={props.hasIntroAnimationPlayed ? {} : { x: 0 }}
                                        transition={{ ...leftTransition, ease: [0.5, 0, 0.1, 1] }}
                                        className="animation__outer animation__with_solar"
                                    >
                                        <span className="animation__with_solar__inner">{props.title_line_2}</span>
                                    </motion.span>
                                </motion.span>
                            </motion.h1>
                        </div>
                        <div className="hero_component__content__cta">
                            <motion.div 
                                initial={props.hasIntroAnimationPlayed ? {} : { scaleX: 0 }}
                                animate={props.hasIntroAnimationPlayed ? {} : { scaleX: 1 }}
                                transition={inFromRightTransition}
                                className="hero_component__content__cta__border"
                            ></motion.div>
                            <motion.div 
                                initial={props.hasIntroAnimationPlayed ? { opacity: 0 } : { y: '-20%', opacity: 0 }}
                                animate={props.hasIntroAnimationPlayed ? { opacity: 1 } : { y: 0, opacity: 1 }}
                                transition={downTransition}
                                className="hero_component__content__cta__outer"
                            >
                                <p>{props.description}</p>
                                <ButtonComponent label={props.call_to_action_text} hover={props.call_to_action_hover_text} to={props.call_to_action_link} />
                            </motion.div>
                        </div>
                    </div>
                </motion.li>
            ) : null}
        </AnimatePresence>
    )
}