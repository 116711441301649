import { useState, useEffect, useRef } from "react"
import { motion } from "framer-motion"

import "components/ProductsList/ProductsListComponent.scss"

import ButtonComponent from "components/Button/ButtonComponent"

import nextIcon from "assets/images/right-arrow-icon.svg"
import prevIcon from "assets/images/left-arrow-icon.svg"

const subtitleTransition = {
    duration: 0.8, 
    ease: "easeInOut"
}

export default function ProductsListComponent ( props ) {
    const [offset, setOffset] = useState(0)
    const [itemWidth, setItemWidth] = useState(0)
    const [isAtEnd, setIsAtEnd] = useState(false)
    const [isAtStart, setIsAtStart] = useState(true)
    const perPage = window.innerWidth < 960 ? 1 : 3
    const ref = useRef(null)

    const nextProduct = () => {
        setOffset(prev => prev+perPage < props.products.length ? prev + 1 : prev)
    }

    const previousProduct = () => {
        setOffset(prev => prev > 0 ? prev - 1 : prev)
    }

    useEffect(() => {
        if( ref.current ) {
            setItemWidth(ref.current.clientWidth/perPage)
        }
    }, [ref.current])

    useEffect(() => {
        setIsAtEnd(offset+perPage >= props.products.length ? true : false)
        setIsAtStart(offset === 0 ? true : false)
    }, [offset])

    if( !props.products.length ) return null

    return (
        <div className="products_list">
            <div className="products_list__header">
                <motion.h2 
                    className="products_list__header__title"
                    initial={{ y: "100%", opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    viewport={{ amount: 1, once: true }}
                    transition={{ ...subtitleTransition, delay: 0 }}
                >
                    Our Range of {props.category_name}
                </motion.h2>
                <div className="products_list__header__controls">
                    <button onClick={previousProduct} className="products_list__header__controls__button">
                        <img src={prevIcon} alt="Previous" style={{ transform: "rotate(-180deg)", opacity: isAtStart ? 0.5 : 1 }} />
                    </button>
                    <button onClick={nextProduct} className="products_list__header__controls__button">
                        <img src={nextIcon} alt="Next" style={{ opacity: isAtEnd ? 0.5 : 1 }} />
                    </button>
                </div>
            </div>
            <div ref={ref} className="products_list__items_container">
                <ul className="products_list__items" style={{ transform: `translateX(-${itemWidth*offset}px)` }}>
                    {props.products.map((product, product_index) => (
                        <motion.li 
                            key={product_index} 
                            className="products_list__items__item"
                            initial={{ y: "5%", opacity: 0 }}
                            transition={{ duration: 0.8, delay: 0, ease: "easeInOut" }}
                            whileInView={{ y: 0, opacity: 1 }}
                            viewport={{ amount: 0.4, once: true }}
                        >
                            <div className="products_list__items__item__header">
                                <img className="products_list__items__item__header__image" src={product.featured_image} alt={product.title} />
                                <div className="products_list__items__item__header__info">
                                    {/* <div className="products_list__items__item__header__info__dimensions">{product.dimensions}</div> */}
                                    <h2 className="products_list__items__item__header__info__title">{product.title}</h2>
                                    <h3 className="products_list__items__item__header__info__product_type">{props.category_name}</h3>
                                    <div className="products_list__items__item__header__info__buttons">
                                        {product.metadata.datasheet ? <ButtonComponent to="/" label="Data Sheet" hover="Download" alt={true} href={product.metadata.datasheet.url} target="_blank" /> : null}
                                        <ButtonComponent to={`/products/${product.slug}`} label="Learn More" hover="View Product" alt={true} />
                                    </div>
                                </div>
                            </div>
                            {product.metadata.specs.length ? (
                                    <ul className="products_list__items__item__attributes">
                                        {product.metadata.specs.map((attribute, attribute_index) => (
                                            <li key={attribute_index} className="products_list__items__item__attributes__item">
                                                <span className="products_list__items__item__attributes__item__value">{attribute.value}</span>
                                                <span className="products_list__items__item__attributes__item__name">{attribute.name}</span>
                                            </li>
                                        ))}
                                    </ul>
                            ) : null}
                        </motion.li>
                    ))}
                </ul>
            </div>
        </div>
    )
}