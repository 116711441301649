import ButtonComponent from "components/Button/ButtonComponent"

export default function DistributorsListItem( props ) {

    return (
        <li className="distributors_view__regions__item__distributors__item">
            <div className="distributors_view__regions__item__distributors__item__header">
                <h3 className="distributors_view__regions__item__distributors__item__title">{props.name}</h3>
                <p className="distributors_view__regions__item__distributors__item__location">{props.location}</p>
                <p className="distributors_view__regions__item__distributors__item__phone">Phone: {props.phone}</p>
            </div>
        </li>
    )
}