import { useEffect, useRef, useState } from "react"

import "./HeroComponent.scss"

import HeroComponentItem from "components/Hero/HeroComponentItem"

export default function HeroComponent( props ) {
    const heroRef = useRef()
    const [isVisible, setIsVisible] = useState(false)
    const [activeSlide, setActiveSlide] = useState(0);
    const [hasIntroAnimationPlayed, setHasIntroAnimationPlayed] = useState(false);

    const callback = (entries) => {
        const [entry] = entries
        setIsVisible(entry.isIntersecting)
    }

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0
        }
        let current = heroRef.current

        const observer = new IntersectionObserver(callback, options)
        if (heroRef.current) observer.observe(heroRef.current)

        return () => {
            if (current) observer.unobserve(current)
        }
    }, [heroRef])

    useEffect(() => {
        const timer = setInterval(() => {
            setHasIntroAnimationPlayed(true);
            if(props.slides.length-1 <= activeSlide) {
                setActiveSlide(0);
            } else {
                setActiveSlide(activeSlide+1);
            }
        }, 15000);
    
        return () => {
          clearInterval(timer);
        };
    }, [activeSlide, props.slides.length]);

    return (
        <div className="hero_component" ref={heroRef}>
            {props.slides.length ? (
                <ul className="hero_component__list">
                    {props.slides.map((slide, index) => <HeroComponentItem is_active={activeSlide === index} hasIntroAnimationPlayed={hasIntroAnimationPlayed} isVisible={isVisible} key={index} subtitle={slide.subtitle} title_line_1={slide.title_line_1} title_line_2={slide.title_line_2} video={slide.video.url} description={slide.description} call_to_action_text={slide.call_to_action_text} call_to_action_hover_text={slide.call_to_action_hover_text} call_to_action_link={slide.call_to_action_link} />)}
                </ul>
            ) : null}
        </div>
    )
}