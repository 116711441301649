import { motion } from "framer-motion"

import "./SectionIntroComponent.scss"

const transition = {
    duration: 1,
    ease: [0.5, 0, 0.1, 1]
}

const subtitleTransition = {
    duration: 0.8, 
    delay: 0.6,
    ease: "easeInOut"
}

export default function SectionIntroComponent( props ) {

    return (
        <div className="section_intro_component">
            <motion.h3 
                className="section_intro_component__subtitle"
                initial={{ y: "100%", opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ amount: 1, once: true }}
                transition={subtitleTransition}
            >
                {props.subtitle}
            </motion.h3>
            <motion.h2 
                className="section_intro_component__title"
                initial={{ x: "10%", opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ amount: 0.1, once: true }}
                transition={transition}
            >
                {props.title}
            </motion.h2>
            <motion.div 
                className="section_intro_component__description" 
                dangerouslySetInnerHTML={{__html: props.description}} 
                initial={{ y: "10%", opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ ...transition, delay: 0.4 }}
            />
        </div>
    )
}