import { useState, useEffect } from "react"
import { useParams  } from "react-router-dom"
import { Helmet } from "react-helmet";

import SubCategoryViewItem from "views/SubCategory/SubCategoryViewItem"

import PageHeroComponent from "components/PageHero/PageHeroComponent"

export default function SubCategoryView( props ) {
    const taxonomy = 'product_categories'
    const { category_slug, sub_category_slug } = useParams()
    const [category, setCategory] = useState(null)
    const [subCategory, setSubCategory] = useState(null)

    useEffect(() => {
        if(category) {
            const sub_category = category.terms.find(sub_category => sub_category.slug === sub_category_slug)
            setSubCategory(sub_category)
        }
    }, [category, sub_category_slug])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_REST_API_URL}/taxonomy/${taxonomy}/${category_slug}?include_posts=products&depth=1`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then((data) => setCategory(data))

    }, [category_slug])

    if (!subCategory) return null

    return (
        <div className="category_view">
            <Helmet>
                {subCategory.metadata.seo_title ? <title>{subCategory.metadata.seo_title}</title> : null}
                {subCategory.metadata.seo_description ? <meta name="description" content={subCategory.metadata.seo_description} /> : null}
            </Helmet>
            <PageHeroComponent title={subCategory.name} subtitle="Products" description={category.metadata.hero_description} image={subCategory.metadata.hero_image.url} />
            {subCategory.posts.length ? (
                <div className="category_view__children">
                    <SubCategoryViewItem parent_category={category.slug} category={subCategory} />
                </div>
            ) : null}
        </div>
    )
}