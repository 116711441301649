
import "./LoadingView.scss"

export default function LoadingView( props ) {

    return (
        <div className="loading_view">
            <h1>Loading</h1>
        </div>
    )
}