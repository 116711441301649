import { useEffect, useState } from "react"
import { Helmet } from "react-helmet";

import "./TermsView.scss"

import SectionIntroComponent from "components/SectionIntro/SectionIntroComponent"

export default function TermsView( props ) {
    const slug = 'terms-and-conditions'
    const [data, setData] = useState(null)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setData(data))
    }, [])

    if(!data) return null

    return (
        <div className="terms_view">
            <Helmet>
                {data.metadata.seo_title ? <title>{data.metadata.seo_title}</title> : null}
                {data.metadata.seo_description ? <meta name="description" content={data.metadata.seo_description} /> : null}
            </Helmet>
            <SectionIntroComponent subtitle={data.metadata.subtitle} title={data.metadata.title} description={data.content} />
        </div>
    )
}