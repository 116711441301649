import "./VideoComponent.scss"

export default function VideoComponent( props ) {

    return (
        <div className="video_component">
            <video className="video_component__video" autoPlay muted loop playsInline>
                <source src={props.src} type="video/mp4" />
            </video>
        </div>
    )
}