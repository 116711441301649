import { Link } from "react-router-dom"

import "./ButtonComponent.scss"

export default function ButtonComponent( props ) {

    return (
        <>
            {props.href ? (
                <a href={props.href} className={props.alt ? "button_component alt" : "button_component"} target={props.target ? props.target : "_self"}>
                    <span className="button_component__label">{props.label}</span>
                    <span className="button_component__hover_label">{props.hover}</span>
                </a>
            ) : (
                <Link to={props.to} className={props.alt ? "button_component alt" : "button_component"}>
                    <span className="button_component__label">{props.label}</span>
                    <span className="button_component__hover_label">{props.hover}</span>
                </Link>
            )}
        </>
    )
}