import downloadIcon from "assets/images/download-icon.svg"

export default function DownloadsListItem( props ) {

    return (
        <li className="downloads_view__downloads__list__item">
            <h3 className="downloads_view__downloads__list__item__title">
                <a className="downloads_view__downloads__list__item__view_btn" href={props.link} target="_blank">{props.name}</a>
            </h3>
            {props.link ? <a className="downloads_view__downloads__list__item__warranty" href={props.link} target="_blank"><img src={downloadIcon} alt="Download" heigh="30" /></a> : null}
        </li>
    )
}