import { useState, useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import usePageTracking from "hooks/usePageTracking"

import HeaderComponent from "components/Header/HeaderComponent"
import FooterComponent from "components/Footer/FooterComponent"
import HomeView from "views/Home/HomeView"
import CategoryView from "views/Category/CategoryView"
import SubCategoryView from "views/SubCategory/SubCategoryView"
import ProductView from "views/Product/ProductView"

import AboutView from "views/About/AboutView"
import DistributorsView from "views/Distributors/DistributorsView"
import DownloadsView from "views/Downloads/DownloadsView"
import TermsView from "views/Terms/TermsView"

function App() {
  const location = useLocation()
  usePageTracking()
  const [isHomeView, setIsHomeView] = useState(location.pathname === "/" ? true : false)

  useEffect(() => {
    setIsHomeView(location.pathname === "/" ? true : false)
  }, [location.pathname])

  return (
    <div className={isHomeView ? "application_container is_collapsible" : "application_container"}>
      <HeaderComponent isCollapsible={isHomeView} />
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="/categories/:category_slug" element={<CategoryView />} />
        <Route path="/categories/:category_slug/:sub_category_slug" element={<SubCategoryView />} />
        <Route path="/products/:product_slug" element={<ProductView />} />
        <Route path="/about-us" element={<AboutView />} />
        <Route path="/distributors" element={<DistributorsView />} />
        <Route path="/downloads" element={<DownloadsView />} />
        <Route path="/terms-and-conditions" element={<TermsView />} />
      </Routes>
      <FooterComponent />
    </div>
  );
}

export default App;
