import { useState } from "react"
import { motion } from "framer-motion"

import ButtonComponent from "components/Button/ButtonComponent"
import EmbedFormContactComponent from "components/EmbedFormContact/EmbedFormContactComponent"

import "./CallToActionComponent.scss"

const subtitleTransition = {
    duration: 0.8, 
    delay: 0.6,
    ease: "easeInOut"
}

export default function CallToActionComponent( props ) {
    const [showModal, setShowModal] = useState(false)

    const hideModal = () => {
        setShowModal(false)
    }

    return (
        <div className="call_to_action_component">
            <img className="call_to_action_component__image" src={props.src} alt={props.alt} />
            <div className="call_to_action_component__content">
                <motion.h3 
                    className="call_to_action_component__content__title"
                    initial={{ x: "10%", opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={subtitleTransition}
                    >
                    {props.title}
                </motion.h3>
                <motion.p 
                    className="call_to_action_component__content__description"
                    initial={{ x: "10%", opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ ...subtitleTransition, delay: 0.8 }}
                >
                    {props.description}
                </motion.p>
                <motion.div 
                    className="call_to_action_component__content__link"
                    initial={{ x: "10%", opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ ...subtitleTransition, delay: 1 }}
                >
                    {props.is_popup ? (
                        <button onClick={() => setShowModal(true)} className="solutions_component__list__item__content__footer__button">
                            <span className="solutions_component__list__item__content__footer__button__label">{props.linkText}</span>
                            <span className="solutions_component__list__item__content__footer__button__hover_label">{props.linkHoverText}</span>
                        </button>
                    ) : <ButtonComponent label={props.linkText} hover={props.linkHoverText} to={props.link} href={props.href} target={props.target} /> }
                </motion.div>
            </div>
            {props.is_popup && props.hbspt ? <EmbedFormContactComponent hbspt={props.hbspt} form_id={props.form_id} showModal={showModal} hideModal={hideModal} /> : null}
        </div>
    )
}