import { useEffect, useState } from "react"
import { useScript } from "hooks/useScript" 
import { Helmet } from "react-helmet";

import "./HomeView.scss"

import LoadingView from "views/Loading/LoadingView"
import HeroComponent from "components/Hero/HeroComponent"
import SectionIntroComponent from "components/SectionIntro/SectionIntroComponent"
import CategoriesComponent from "components/Categories/CategoriesComponent"
import CallToActionComponent from "components/CallToAction/CallToActionComponent"
import SolutionsComponent from "components/Solutions/SolutionsComponent"
import PreLoaderComponent from 'components/PreLoader/PreLoaderComponent';

export default function HomeView( props ) {
    const slug = 'home'
    const [assetsPreloaded, setAssetsPreloaded] = useState(false)
    const [data, setData] = useState(null)
    const [categories, setCategories] = useState(null)
    const [preloadSrcList, setPreloadSrcList] = useState([])
    const { hbspt } = useScript('//js.hsforms.net/forms/embed/v2.js', 'hbspt')

    useEffect(() => {
        fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setData(data))

        fetch(`${process.env.REACT_APP_REST_API_URL}/taxonomy/product_categories/`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setCategories(data))
    }, [])

    useEffect(() => {
        if(data) setPreloadSrcList(data.metadata.hero.length ? data.metadata.hero.map(item => item.video) : [])
    }, [data])

    useEffect(() => {
        console.log(categories)
    }, [categories])

    const preloadAssetList = async () => {
        let loaded = await PreLoaderComponent(preloadSrcList)
        setAssetsPreloaded(loaded)
    }

    useEffect(() => {
        if(preloadSrcList.length) preloadAssetList()
    }, [preloadSrcList])

    if (!data && !assetsPreloaded) {
        return <LoadingView />
    }
    
    return (
        <div className="home_view">
            <Helmet>
                {data.metadata.seo_title ? <title>{data.metadata.seo_title}</title> : null}
                {data.metadata.seo_description ? <meta name="description" content={data.metadata.seo_description} /> : null}
            </Helmet>
            <HeroComponent slides={data.metadata.hero} />
            <SectionIntroComponent subtitle={data.metadata.subtitle} title={data.metadata.title} description={data.metadata.description} />
            {categories ? <CategoriesComponent categories={categories.terms} /> : null}
            <CallToActionComponent src={data.metadata.cta_image.url} alt={data.metadata.alt} title={data.metadata.cta_title} description={data.metadata.cta_description} link={data.metadata.cta_call_to_action_link} linkText={data.metadata.cta_call_to_action_text} linkHoverText={data.metadata.cta_call_to_action_hover_text} />
            <SectionIntroComponent subtitle={data.metadata.solutions.subtitle} title={data.metadata.solutions.title} description={data.metadata.solutions.description} />
            <SolutionsComponent hbspt={hbspt} form_id="bee2432a-d8ff-47bd-b698-01798aabe390" solutions={data.metadata.solutions.solutions_list} />
        </div>
    )
}