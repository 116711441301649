import { useState, useEffect } from "react"
import { useParams  } from "react-router-dom"
import { Helmet } from "react-helmet";

import CategoryViewDescription from "views/Category/CategoryViewDescription"
import CategoryViewItem from "views/Category/CategoryViewItem"
import SubCategoryViewItem from "views/SubCategory/SubCategoryViewItem"

import PageHeroComponent from "components/PageHero/PageHeroComponent"

import "./CategoryView.scss"

export default function CategoryView( props ) {
    const taxonomy = 'product_categories'
    const { category_slug } = useParams()
    const [category, setCategory] = useState(null)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_REST_API_URL}/taxonomy/${taxonomy}/${category_slug}?include_posts=products&depth=1`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then((data) => setCategory(data))
    }, [category_slug])

    if (!category) return null

    return (
        <div className="category_view">
            <Helmet>
                {category.metadata.seo_title ? <title>{category.metadata.seo_title}</title> : null}
                {category.metadata.seo_description ? <meta name="description" content={category.metadata.seo_description} /> : null}
            </Helmet>
            <PageHeroComponent title={category.name} subtitle={category.metadata.hero_subtitle} description={category.metadata.hero_description} image={category.metadata.hero_image.url} />
            {category.metadata.title ? <CategoryViewDescription slug={category.slug} subtitle={category.metadata.subtitle} title={category.metadata.title} description={category.metadata.description} categories={category.terms} /> : null}
            {category.terms.length ? (
                <div className="category_view__children">
                    {category.terms.map((child_category, index) => <CategoryViewItem key={index} parent_category={category.slug} category={child_category} /> )}
                </div>
            ) : (
                <>
                    {category.posts.length ? (
                        <div className="category_view__children">
                            <SubCategoryViewItem parent_category={category.slug} category={category} />
                        </div>
                    ) : null}
                </>
            )}
        </div>
    )
}