import { useState, useEffect } from "react"
import { useParams  } from "react-router-dom"
import { Helmet } from "react-helmet";

import PageHeroComponent from "components/PageHero/PageHeroComponent"

import "./ProductView.scss"

export default function CategoryView( props ) {
    const { product_slug } = useParams()
    const [data, setData] = useState(null)
    const [keyPointsSegment1, setKeyPointsSegment1] = useState([])
    const [keyPointsSegment2, setKeyPointsSegment2] = useState([])
    const [categoriesList, setCategoriesList] = useState('')

    useEffect(() => {
        fetch(`${process.env.REACT_APP_REST_API_URL}/post/${product_slug}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setData(data))
    }, [product_slug])

    useEffect(() => {
        if (data !== null && data.metadata.key_points.length) {
            setKeyPointsSegment1(data.metadata.key_points.slice(0, 3))
            setKeyPointsSegment2(data.metadata.key_points.slice(3, data.metadata.key_points.length))
        }
    }, [data])

    useEffect(() => {
        setCategoriesList(data && data.hasOwnProperty('categories') ? data.categories.join(' ') : '')
    }, [data])

    if (!data) return null

    return (
        <div className="product_view">
            <Helmet>
                {data.metadata.seo_title ? <title>{data.metadata.seo_title}</title> : <title>{`${data.title} | enerSol - Powered by the Sun`}</title>}
                {data.metadata.seo_description ? <meta name="description" content={data.metadata.seo_description} /> : <meta name="description" content={`${categoriesList} - ${data.metadata.dimensions}`} />}
            </Helmet>
            <PageHeroComponent title={data.title} subtitle={categoriesList} description={data.metadata.dimensions} image={data.metadata.hero_image.url} cta_label="Datasheet" cta_label_hover="Download" cta_link={data.metadata.datasheet.url} />
            <div className="product_view__content">
                {data.metadata.specs.length ? (
                    <ul className="product_view__content__attributes">
                        {data.metadata.specs.map((attribute, attribute_index) => (
                            <li key={attribute_index} className="product_view__content__attributes__item">
                                <span className="product_view__content__attributes__item__value">{attribute.value}</span>
                                <span className="product_view__content__attributes__item__name">{attribute.name}</span>
                            </li>
                        ))}
                    </ul>
                ) : null}
                <div className="product_view__content__details">
                    {keyPointsSegment1.length ? (
                        <ul className="product_view__content__details__key_points_list">
                            {keyPointsSegment1.map((key_point, key_point_index) => (
                                <li key={key_point_index} className="product_view__content__details__key_points_list__item">
                                    <div className="product_view__content__details__key_points_list__item__icon">
                                        <img className="product_view__content__details__key_points_list__item__icon__src" src={`${process.env.REACT_APP_SITE_URL}/wp-content/themes/enersol/assets/images/product/icons/${key_point.icon}.png`} alt={key_point.name} />
                                    </div>
                                    <div className="product_view__content__details__key_points_list__item__title">
                                        {key_point.title}
                                    </div>
                                    <div className="product_view__content__details__key_points_list__item__description">
                                        {key_point.description}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : null}
                    <div className="product_view__content__details__image">
                        <img className="product_view__content__details__image__src" src={data.featured_image} alt={data.title} />
                    </div>
                    {keyPointsSegment2.length ? (
                        <ul className="product_view__content__details__key_points_list">
                            {keyPointsSegment2.map((key_point, key_point_index) => (
                                <li key={key_point_index} className="product_view__content__details__key_points_list__item">
                                    <div className="product_view__content__details__key_points_list__item__icon">
                                        <img className="product_view__content__details__key_points_list__item__icon__src" src={`${process.env.REACT_APP_SITE_URL}/wp-content/themes/enersol/assets/images/product/icons/${key_point.icon}.png`} alt={key_point.name} />
                                    </div>
                                    <div className="product_view__content__details__key_points_list__item__title">
                                        {key_point.title}
                                    </div>
                                    <div className="product_view__content__details__key_points_list__item__description">
                                        {key_point.description}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : null}
                </div>
            </div>
        </div>
    )
}