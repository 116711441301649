import { useEffect, useState } from "react"
import { Helmet } from "react-helmet";

import "./DownloadsView.scss"

import PageHeroComponent from "components/PageHero/PageHeroComponent"
import DownloadsList from "views/Downloads/DownloadsList"

export default function DownloadsView( props ) {
    const slug = 'downloads'
    const post_type = 'downloads'
    const category = 'download_categories'
    const [data, setData] = useState(null)
    const [categories, setCategories] = useState(null)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setData(data))

        fetch(`${process.env.REACT_APP_REST_API_URL}/taxonomy/${category}?include_posts=${post_type}&depth=1`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then((data) => setCategories(data))
    }, [])

    if(!data || !categories) return null

    return (
        <div className="downloads_view">
            <Helmet>
                {data.metadata.seo_title ? <title>{data.metadata.seo_title}</title> : null}
                {data.metadata.seo_description ? <meta name="description" content={data.metadata.seo_description} /> : null}
            </Helmet>
            <PageHeroComponent title={data.title} description={data.metadata.description} image={data.metadata.image.url} />
            <DownloadsList categories={categories} />
        </div>
    )
}