import { motion } from "framer-motion"

import "./PageHeroComponent.scss"

import ButtonComponent from "components/Button/ButtonComponent"

const transition = {
    duration: 1,
    ease: [0.5, 0, 0.1, 1]
}

const subtitleTransition = {
    duration: 0.8, 
    ease: "easeInOut"
}

export default function PageHeroComponent( props ) {
   
    return (
        <div className="page_hero_component">
            <img className="page_hero_component__image" src={props.image} alt="{props.title}" />
            <div className="page_hero_component__content">
                <motion.h2 
                    className="page_hero_component__content__subtitle"
                    initial={{ y: "100%", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ ...subtitleTransition, delay: 1.5 }}
                >
                    {props.subtitle}
                </motion.h2>
                <motion.h1 
                    className="page_hero_component__content__title"
                    initial={{ clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)" }}
                    animate={{ clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" }}
                    transition={{ ...transition, delay: 0.5, duration: 1 }}
                >
                    {props.title}
                    {props.cta_label ? (
                        <ButtonComponent label={props.cta_label} hover={props.cta_label_hover} href={props.cta_link} target="_blank" />
                    ) : null}
                </motion.h1>
                <motion.div 
                    className="page_hero_component__content__introduction"
                    initial={{ y: "20%", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ ...transition, delay: 2.3 }}
                >
                        {props.description}
                </motion.div>
            </div>
        </div>
    )
}