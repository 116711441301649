import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'UA-222193058-1');
  }, [location])
}

export default usePageTracking