import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { ScrollToYComponent } from 'components/Utils/UtilsComponent'

export default function ScrollToTopComponent() {
  const { pathname } = useLocation();

  useEffect(() => {
    ScrollToYComponent(0, 1500, 'easeInOutQuint');
  }, [pathname]);

  return null;
}
