import { useEffect, useState } from "react"

import "components/EmbedFormContact/EmbedFormContactComponent.scss"

import closeIcon from "assets/images/close-icon.svg"

export default function EmbedFormContactComponent({ hbspt, form_id, showModal, hideModal }) {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        hbspt.forms.create({
            target: "#hubspotForm",
            region: "na1",
            portalId: "19822138",
            formId: form_id        
        })
    }, [])

    useEffect(() => {
        setIsVisible(showModal)
    }, [showModal])

    const close = () => {
        hideModal()
    }

    return (
        <div className="embed_form" style={{ opacity: isVisible ? 1 : 0, pointerEvents: isVisible ? 'initial' : 'none' }}>
            <div className="embed_form__modal">
                <div className="embed_form__modal__header">
                    <button className="embed_form__modal__header__close_btn" onClick={close}>
                        <img src={closeIcon} alt="Close" />
                    </button>
                </div>
                <div id="hubspotForm" className="embed_form__modal__content"></div>
            </div>
        </div>
    )
}