import DistributorsListItem from "views/Distributors/DistributorsListItem"

export default function DistributorsList( props ) {

    return (
        <>
            {props.regions.length ? (
                <ul className="distributors_view__regions">
                    {props.regions.map( ( region, index ) => (
                        <li key={index} className="distributors_view__regions__item">
                            <h2 className="distributors_view__regions__item__title">{region.name}</h2>
                            {region.posts ? (
                                <ul className="distributors_view__regions__item__distributors">
                                    {region.posts.map( ( distributor, index ) => <DistributorsListItem key={index} name={distributor.title} location={distributor.metadata.address} phone={distributor.metadata.phone} email={distributor.metadata.email} /> )}
                                </ul>
                            ) : null}
                        </li>
                    ))}
                </ul>
            ) : null}
        </>
    )
}